import generateModule from "@/store/templates/b-select/index";
import { question as schema } from "@/store/schema";
import apiModule from "@/api/question";

export default {
  ...generateModule(schema, apiModule, {
    state: {
      entities: ["localization", "questionOption"],
      filters: {
        filterable: true,
        root: true
      }
    }
  })
};
